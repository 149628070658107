import request from '@/api/request.js'

//获取楼栋列表
export function getLouDongListFn (data){
  return request({
    url:'/village/building',
    method:'get',
    params:data
  })
}
//新增楼栋
export function addLouDongFn (data){
  return request({
    url:'/village/building/add',
    method:'post',
    data:data
  })
}
//获取楼栋详情
export function getLouDongDetailsFn (data){
  return request({
    url:'/village/building/' + data.id,
    method:'get',
    params:data
  })
}
//编辑楼栋
export function editLouDongFn (data){
  return request({
    url:'/village/building/' + data.id +  '/update',
    method:'post',
    data:data
  })
}
//删除楼栋
export function deleteLouDongFn (data){
  return request({
    url:'/village/building/add',
    method:'post',
    data:data
  })
}
